<template>
  <ui-form
    @submit="handleSubmit"
    :submitText="$t('actions.save')"
    @cancel="handleCancel"
  >
    <!-- Title + instructor -->
    <div class="flex flex-row flex-wrap gap-x-3 justify-between">
      <!-- Hidden slug value to identify public course events -->
      <input type="hidden" v-model="courseEvent.slug" />

      <ui-form-field
        :label="$t('fields.title')"
        rules="required"
        class="flex-grow"
        :minimize="true"
      >
        <el-input
          type="text"
          :placeholder="$t('placeholders.title')"
          v-model="courseEvent.title"
        ></el-input>
      </ui-form-field>

      <ui-form-field
        :label="$t('language.language')"
        rules="required"
        class="grow-0"
        :minimize="true"
      >
        <el-select v-model="courseEvent.language" placeholder="Select">
          <el-option
            v-for="item in supportedLanguages()"
            :key="item.value"
            :label="$t(`language.${item.label}`)"
            :value="item.value"
          />
        </el-select>
      </ui-form-field>

      <ui-form-field
        :label="$t('fields.instructor')"
        rules="required"
        :minimize="true"
      >
        <el-select
          v-model="courseEvent.instructorModel"
          value-key="id"
          :no-data-text="$t('errors.no_data')"
          :loading="this.fetchingInstructors"
          :loading-text="$t('actions.fetching_data')"
          :placeholder="$t('actions.select')"
        >
          <el-option
            v-for="item in instructors"
            :key="item.id"
            :label="item.firstName + ' ' + item.lastName"
            :value="item"
          />
        </el-select>
      </ui-form-field>
    </div>

    <div class="flex flex-col gap-x-5">
      <!-- Description -->
      <div class="flex flex-row">
        <ui-form-field
          class="flex-grow"
          :minimize="true"
          :label="$t('fields.description')"
          rules="required"
        >
          <rich-text-field
            type="textarea"
            :rows="3"
            :placeholder="$t('placeholders.description')"
            v-model="courseEvent.description"
          ></rich-text-field>
        </ui-form-field>
      </div>
      <div class="flex flex-row">
        <ui-form-field
          :label="$t('fields.certificateTitle')"
          rules="required"
          class="flex-grow"
          :minimize="true"
        >
          <el-input
            type="text"
            :placeholder="$t('placeholders.certificateTitle')"
            v-model="courseEvent.certificateTitle"
          ></el-input>
        </ui-form-field>

        <div class="flex flex-col justify-end pb-3 ml-5 w-48">
          <!-- Preview START -->
          <ui-button
            :class="isNewCourseEvent ? 'disabled w-32 mb-2' : 'grey w-32 mb-2' "
            :title="isNewCourseEvent ? `${$t('misc.previewDisabled')}` : ''"
            :style="{ height: '43px', width: '100% !important' }"
            @click="handleCertificatePreviewClicked()"
          >
            {{ $t("actions.preview_certificate") }}
          </ui-button>
          <!-- Preview END -->
        </div>
      </div>
      <div>
        <!-- Course Description START -->
        <ui-form-field
          :label="$t('fields.certificateDescription')"
          class="flex-grow"
          rules="required"
          :minimize="true"
        >
          <el-input
            :placeholder="$t('placeholders.certificate_description')"
            :rows="3"
            type="textarea"
            v-model="courseEvent.certificateDescription"
          ></el-input>
        </ui-form-field>
        <!-- Course Description END -->
      </div>

      <div class="flex flex-row justify-between">
        <!-- Internal description START -->
        <ui-form-field
          class="flex-grow"
          :minimize="true"
          :label="$t('fields.internalDescription')"
        >
          <rich-text-field
            :rows="2"
            type="textarea"
            :placeholder="$t('placeholders.internal_comment')"
            v-model="courseEvent.internalDescription"
          >
            <div>
              <!-- Edit Fields START -->
              <ui-button class="grey" @click="addFieldsModalButtonClicked()">
                {{ $t("fields.edit_fields") }}
              </ui-button>
              <ul
                class="ml-5 list-disc"
                v-if="courseEvent.field1 || courseEvent.field2"
              >
                <transition name="fade">
                  <li
                    class="text-gray-600 font-bold text-sm"
                    v-if="courseEvent.field1"
                  >
                    {{ courseEvent.field1 }}
                  </li>
                </transition>
                <transition name="fade">
                  <li
                    class="text-gray-600 font-bold text-sm"
                    v-if="courseEvent.field2"
                  >
                    {{ courseEvent.field2 }}
                  </li>
                </transition>
              </ul>
              <!-- Edit Fields END -->
            </div>
          </rich-text-field>
        </ui-form-field>
        <!-- Internal description END -->

        <div class="flex flex-col items-start justify-end ml-5 w-48 pt-12">
          <p
            v-if="courseEvent.field1 || courseEvent.field2"
            class="italic text-sm whitespace-no-wrap"
          >
            {{ getAddedFieldsDisplayText() }}
          </p>

          <!-- Edit Fields START -->
          <ui-button
            :style="{ height: '43px', width: '100% !important' }"
            :class="
              !(courseEvent.field1 || courseEvent.field2)
                ? 'grey w-32 mb-5'
                : 'grey w-32 mb-5'
            "
            @click="addFieldsModalButtonClicked()"
          >
            {{ $t("fields.edit_fields") }}
          </ui-button>
          <!-- Edit Fields END -->
        </div>
      </div>

      <!-- File upload -->
      <div class="mb-10 flex flex-col flex-nowrap flex-grow justify-start">
        <ui-form-field
          :label="$t('actions.upload_files')"
          class="flex-grow"
          :minimize="true"
        >
          <el-upload
            action=""
            accept="image/*"
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :on-change="setPicture"
            class="background_form_item"
          >
            <ui-button class="grey">
              {{ $t("labels.company.registration_banner") }}
              <i class="el-icon-upload2"></i>
            </ui-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>

          <ui-button
            type="red"
            @click="setBackgroundDeleted"
            class="background_form_item delete_button"
            v-if="backgroundExists"
          >
            {{ $t(deleteBackgroundText) }}
          </ui-button>
        </ui-form-field>
      </div>

      <!-- Add session -->
      <div>
        <ui-button class="bg-green-200" @click="handleNewSession">
          {{ $t("actions.add_new_group") }}
        </ui-button>
      </div>
    </div>

    <course-event-form-session-list
      @editSession="editSession"
      @deleteSession="deleteSession"
      class="mt-5 mb-5 border rounded"
      :sessions="courseEvent.sessions == null ? [] : courseEvent.sessions"
    />

    <!-- Modals -->
    <EditSessionModal
      :key="session != null ? session.id : null"
      :instructors="instructors"
      :default-instructor="
        courseEvent.instructorModel == null ? null : courseEvent.instructorModel
      "
      :default-location="lastSessionLocation"
      :default-start-date="lastSessionEndDate"
      :session="session"
      :show="showEditSessionModal"
      :text="`${$t('actions.add')} ${$tc('models.group', 0)}`"
      :confirmText="$t('actions.add')"
      @confirm="handleSessionEdited"
      @cancel="showEditSessionModal = false"
    />

    <EditRequiredFieldsModal
      v-if="showEditFieldsModal"
      :show="showEditFieldsModal"
      :field1="courseEvent.field1"
      :field2="courseEvent.field2"
      @confirm="handleConfirmAddFieldsModal"
      @cancel="showEditFieldsModal = false"
    />
  </ui-form>
</template>

<script>
import EditRequiredFieldsModal from "@/app/courseevents/components/EditRequiredFieldsModal.vue";
import EditSessionModal from "@/app/courseevents/components/EditSessionModal.vue";
import CourseEventFormSessionList from "@/app/courseevents/components/CourseEventFormSessionList.vue";
import RichTextField from "@/components/form/RichTextField";
import UiButton from "@/components/ui/UiButton";
import UiFormField from "@/components/ui/UiFormField";
import UiForm from "@/components/ui/UiForm.vue";
import slug from "slug";
import { registrationBannerExists } from "../api";

import { SupportedLanguages } from "@/i18n/lang";
import { getInstructors, downloadCertificatePreview } from "../api";

export default {
  props: {
    registrationBanner: {
      type: Object,
      required: true
    },
    courseEvent: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      deleteBackgroundText: "labels.building.delete_background",
      backgroundExists: false,
      backgroundDeleted: false,

      showEditSessionModal: false,
      session: {},

      showEditFieldsModal: false,
      languageModel: {},

      instructors: [],
      fetchingInstructors: false,

      fileList: [],
    };
  },

  computed: {
    isNewCourseEvent: function () {
      return this.courseEvent.id ? false : true;
    },
    /**
     * Computes the location of last session, used for adding new groups and auto-filling field.
     * @returns {string} A location, or null if none found
     */
    lastSessionLocation: function () {
      const lastSession = this.getLastSessionOrNull();

      return lastSession == null ? null : lastSession.location;
    },

    /**
     * Computes a the start date for last session, used for adding new groups and auto-filling field.
     * @returns {Date} A date, or null if none found
     */
    lastSessionEndDate: function () {
      const lastSession = this.getLastSessionOrNull();

      return lastSession == null ? null : new Date(lastSession.endDateTime);
    },
    /**
     * Computes a default location, used for adding new groups and auto-filling field.
     * Default location is set to most recent session's location.
     * @returns {string} A default location, or null if none found
     */
    lastSession: function () {
      const lastSession = this.getLastSessionOrNull();

      return lastSession == null ? null : lastSession;
    },
  },

  components: {
    UiButton,
    CourseEventFormSessionList,
    UiForm,
    EditSessionModal,
    EditRequiredFieldsModal,
    UiFormField,
    RichTextField,
  },

  watch: {
    /** This function is ran when props are loaded - we are checking if any registrationbanners are saved on this course event */
    courseEvent: {
      async handler(_, __) {
        // If new course, no banner exists on it
        if (this.isNewCourseEvent) return;

        try {
          this.backgroundExists = await registrationBannerExists(
            this.courseEvent.id
          );
        } catch (error) {
          console.error(error);
        }
      },
      immediate: true,
    },
  },

  async mounted() {
    await this.getInstructors();
  },

  methods: {
    setPicture(picture) {
      this.registrationBanner.value = picture;
    },
    beforeRemove(file) {
      return this.$confirm(`Cancel the transfer of ${file.name}?`);
    },
    handleCancel() {
      this.$emit("cancel");
    },

    setBackgroundDeleted() {
      this.deleteBackgroundText = "labels.building.deleted";
      this.backgroundDeleted = true;
    },

    /**
     * Used to set the slug value for this course event.
     * Slug value is used as an id for a public, open context where anybody can access it.
     * Computed from title + a random code.
     */
    setSlugFromTitle() {
      const slugValue = slug(this.courseEvent.title);
      const randomness = crypto.randomUUID().slice(0, 11).replaceAll("-", "");
      this.courseEvent.slug = slugValue + "-" + randomness;
    },
    handleCertificatePreviewClicked() {
      if (this.isNewCourseEvent) return;
      downloadCertificatePreview(this.courseEvent.id);
    },
    supportedLanguages() {
      return SupportedLanguages;
    },
    handleRemoveRegistrationBanner(_, __) {
      this.registrationBanner.value = null;
    },

    handleChangedRegistrationBanner(picture) {
      this.registrationBanner.value = picture.name;
    },

    getAddedFieldsDisplayText() {
      // Display shorter strings if they are long
      const lengthLimit = 15;
      let field1Display = this.courseEvent.field1;
      if (field1Display && this.courseEvent.field1.length > lengthLimit) {
        field1Display = field1Display.slice(0, lengthLimit) + "...";
      }

      let field2Display = this.courseEvent.field2;
      if (field2Display && this.courseEvent.field2.length > lengthLimit) {
        field2Display = field2Display.slice(0, lengthLimit) + "...";
      }

      const bothSet = this.courseEvent.field1 && this.courseEvent.field2;
      return bothSet
        ? `${field1Display}, ${field2Display}`
        : `${field1Display == null ? "" : field1Display}${
            field2Display == null ? "" : field2Display
          }`;
    },

    handleNewSession() {
      this.session = {
        id: crypto.randomUUID(),
        language: this.courseEvent.language,
        startDateTime: null,
        endDateTime: null,
        location: null,
        instructorModel: this.courseEvent.instructorModel,
        maxParticipants: 20,
      };
      this.showEditSessionModal = true;
    },

    async getInstructors() {
      this.fetchingInstructors = true;
      this.instructors = await getInstructors();
      this.fetchingInstructors = false;
    },

    handleSessionEdited(item) {
      this.showEditSessionModal = false;
      const sessionExists =
        item.id != null &&
        this.courseEvent.sessions.some((s) => s.id === item.id);
      if (sessionExists) {
        const index = this.courseEvent.sessions.findIndex(
          (s) => s.id === item.id
        );
        this.courseEvent.sessions.splice(index, 1, item);
        this.courseEvent.sessions[index].progress = item.progress;
      } else {
        this.courseEvent.sessions = [...this.courseEvent.sessions, item];
      }
    },

    /**
     * Gets last session based on start time.
     * @returns {Object | null} A session, or null if no appropriate session found
     */
    getLastSessionOrNull() {
      // Check if there a courseevent prop with sessions
      if (this.courseEvent == null || this.courseEvent.sessions == null)
        return null;

      // Check if any sessions
      const sessions = this.courseEvent.sessions;
      const numSessions = sessions.length;
      if (numSessions === 0) return null;

      let lastSession = sessions[0];
      for (let i = 0; i < numSessions; i++) {
        try {
          if (
            new Date(sessions[i].startDateTime) >
            new Date(lastSession.startDateTime)
          ) {
            lastSession = sessions[i];
          }
        } catch {}
      }

      return lastSession == null ? null : lastSession;
    },

    deleteSession(item) {
      this.courseEvent.sessions = this.courseEvent.sessions.filter(
        (o) => o.id !== item.id
      );
    },

    editSession(item) {
      this.session = item;
      this.showEditSessionModal = true;
    },

    async handleSubmit() {
      // Check if this courseevent has a slug created
      if (!this.courseEvent.slug) {
        this.setSlugFromTitle();
      }

      // Should background image be uploaded - this will replace current image
      const shouldUploadImage =
        this.registrationBanner.value &&
        this.registrationBanner.value.raw
          ? true
          : false;

      if (shouldUploadImage) {
        this.$emit("submit", "upload");  // Upload image and update course event
      } else if (this.backgroundDeleted) {
        this.$emit("submit", "delete");  // Delete image and update course event
      } else {
        this.$emit("submit", null);      // Update course event
      }
    },

    addFieldsModalButtonClicked() {
      this.showEditFieldsModal = true;
    },

    handleConfirmAddFieldsModal(field1, field2) {
      this.courseEvent.field1 = field1;
      this.courseEvent.field2 = field2;
      this.showEditFieldsModal = false;
    },

    handleExceed(_, __) {
      this.$message.warning(`You can't upload that many files, limit is 1`);
    },
  },
};
</script>

<style>
.background_form_item {
  display: inline-flex;
}
.delete_button {
  margin-left: 1em;
}
</style>
