var render = function render(){var _vm=this,_c=_vm._self._c;return _c('responsive-ui-list',{attrs:{"disableXScroll":false,"heading":_vm.heading,"headings":[
    _vm.$t('fields.date'),
    _vm.$tc('models.group', 1),
    _vm.$t('fields.location'),
    _vm.$t('fields.participants'), // e.g. 18/20
    _vm.$t('fields.actions'), // slett, edit
  ],"sort":[
    { prop: 'startDateTime', type: false },
    undefined,
    { prop: 'location', type: false },
    { prop: 'progress', prop2: 'progressPercentage', type: 'level2' },
  ],"items":_vm.sessions},scopedSlots:_vm._u([{key:"default",fn:function(props){return [[(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') === _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
              ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
          `}}):_vm._e(),(
          _vm.$d(new Date(props.item.startDateTime), 'fullShort') != _vm.$d(new Date(props.item.endDateTime), 'fullShort')
        )?_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
              ${_vm.$d(new Date(props.item.startDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')} 
              - 
              ${_vm.$d(new Date(props.item.endDateTime), 'fullShort', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
          `}}):_vm._e()],_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`
          ${_vm.$d(new Date(props.item.startDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}
          -
          ${_vm.$d(new Date(props.item.endDateTime), 'time', _vm.$i18n.locale === 'no' ? 'no' : 'en')}`}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"type":"tag","text":props.item.location}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"text":`${props.item.participants == null ? 0 :props.item.participants.length} / ${props.item.maxParticipants}`}}),_c('ui-list-data',{staticClass:"list_data",attrs:{"small":""}},[_c('auth-checker',{attrs:{"accessRoles":['SuperAdmin', 'InstructorAdmin']}},[_c('ui-link',{on:{"click":function($event){return _vm.$emit('editSession', props.item)}}},[_vm._v(" "+_vm._s(_vm.$tc("actions.edit"))+" ")]),_c('ui-button',{staticClass:"ml-5",attrs:{"type":"red","size":"small","uppercase":""},on:{"click":function($event){return _vm.$emit('deleteSession', props.item)}}},[_vm._v(" "+_vm._s(_vm.$t("actions.delete"))+" ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }