<template>
  <auth-checker :accessRoles="['SuperAdmin', 'InstructorAdmin']">
    <ui-card
      v-loading="loading"
      :heading="`${$t('actions.edit')} ${$tc('models.event')}`"
    >
      <course-event-form
        :registration-banner="registrationBanner"
        :course-event="courseEvent"
        @submit="handleSubmit"
        @cancel="handleCancel"
      ></course-event-form>
    </ui-card>
  </auth-checker>
</template>

<script>
import UiCard from "@/components/ui/UiCard";
import CourseEventForm from "../components/CourseEventForm";
import AuthChecker from "../../auth/components/AuthChecker";
import { getCourseEvent, putCourseEvent } from "../api";
import { uploadRegistrationBanner, deleteRegistrationBanner } from "../api";

export default {
  data() {
    return {
      registrationBanner: {},
      courseEvent: {},
      loading: false,
    };
  },

  components: {
    UiCard,
    CourseEventForm,
    AuthChecker,
  },

  methods: {
    async getCourseEventById(id) {
      this.loading = true;
      try {
        this.courseEvent = await getCourseEvent(id);
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t("errors.general"), type: "error" });
        this.loading = false;
      }
    },

    showUploadImageSuccess() {
      this.$message({
        message: this.$t("server_feedback.image_upload_success").toString(),
        type: "success",
      });
    },
    showDeleteImageSuccess() {
      this.$message({
        message: this.$t("server_feedback.image_delete_success").toString(),
        type: "success",
      });
    },
    showDeleteImageFailure() {
      this.$message({
        message: this.$t("server_feedback.image_delete_failure").toString(),
        type: "error",
      });
    },
    showUploadImageFailure() {
      this.$message({
        message: this.$t("server_feedback.image_upload_failure").toString(),
        type: "error",
      });
    },
    showUpdatedCourseEventSuccess() {
      this.$message({
        message: this.$t("server_feedback.course_event_updated").toString(),
        type: "success",
      });
    },
    showGenericError() {
      this.$message({
        message: this.$t("errors.general").toString(),
        type: "error",
      });
    },

    /**
     * Handles image upload
     * @param {string} courseEventId The course event to "attach" the image too
     * @returns {boolean} true if successful (no errors), false if errors occured
     */
    uploadImage: async function (courseEventId) {
      try {
        const response = await uploadRegistrationBanner(
          this.registrationBanner.value.raw,
          courseEventId
        );

        return response.status === 200;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    /**
     * Handles image deletion
     * @param {string} courseEventId The course event to delete the image from
     * @returns {boolean} true if successful (no errors), false if errors occured
     */
     deleteImage: async function (courseEventId) {
      try {
        const response = await deleteRegistrationBanner(
          courseEventId
        );

        return response.status === 200;
      } catch (err) {
        console.error(err);
        return false;
      }
    },

    /**
     * Updates the course event.
     * @returns {Object} The course event
     */
    updateCourseEvent: async function () {
      try {
        // Add ids to sessions
        this.courseEvent.sessions.forEach((item) => {
          item.courseEvent = this.courseEvent.id;
        });
        const response = await putCourseEvent(this.courseEvent);

        if (response.status === 200) {
          return response.data;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },

    handleSubmit: async function (imageAction) {
      this.loading = true;

      // We have to save course first in order to get a valid id for our image upload
      const updatedCourseEvent = await this.updateCourseEvent();
      if (updatedCourseEvent) {
        this.showUpdatedCourseEventSuccess();
      } else {
        this.showGenericError();
        return;
      }

      // Course save went OK, now we can use new id to save image
      const shouldUploadImage = imageAction === "upload";
      if (shouldUploadImage) {
        const success = await this.uploadImage(updatedCourseEvent.id);
        if (success) {
          this.showUploadImageSuccess();
        } else {
          this.showUploadImageFailure();
          return;
        }
      }

      const shouldDeleteImage = imageAction === "delete";
      if (shouldDeleteImage) {
        const success = await this.deleteImage(updatedCourseEvent.id);
        if (success) {
          this.showDeleteImageSuccess();
        } else {
          this.showDeleteImageFailure();
          return;
        }
      }

      this.loading = false;
      this.waitAndGoBack();
    },

    waitAndGoBack() {
      // Waiting 1000ms so we can show toast before returning
      new Promise(() =>
        setTimeout(() => {
          this.$router.back();
        }, 1000)
      );
    },

    handleCancel() {
      this.$router.back();
    },
  },

  mounted() {
    this.$store.commit("changeBreadcrumbs", [
      { name: "models.event", link: "/app/courseevent/" },
      { name: "actions.edit", link: "" },
    ]);
  },

  created() {
    this.getCourseEventById(this.$router.history.current.params.id);
  },
};
</script>
